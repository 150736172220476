#search-mode-help-dialog dd {
  padding: 0.2rem 0.5rem;
}

#search-mode-help-dialog p {
  font-size: small;
  margin-bottom: 0.5rem;
}

#search-mode-help-dialog pre {
  font-size: small;
  background-color: #f6f8fa;
  padding: 1rem;
  margin-top: -0.5rem;
}

.search-mode-help-subtitle-header {
  font-weight: bold;
  font-size: small;
}

.search-mode-help-note-header {
  font-weight: bold;
  display: block;
}

.search-mode-help-example-header {
  font-style: italic;
  font-weight: bold;
  display: block;
}
