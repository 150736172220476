.App {
  height: calc(100vh - 56px);
}

.Page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formTable {
  display: table;
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;
}

.formTableRow {
  display: table-row;
}

.formTableCell {
  display: table-cell;
}

p.formTableCell {
  padding: 0 0.5em;
}

/* https://projects.lukehaas.me/css-loaders/ */
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #555555;
  background: -moz-linear-gradient(left, #555555 10%, rgba(85, 85, 85, 0) 42%);
  background: -webkit-linear-gradient(left, #555555 10%, rgba(85, 85, 85, 0) 42%);
  background: -o-linear-gradient(left, #555555 10%, rgba(85, 85, 85, 0) 42%);
  background: -ms-linear-gradient(left, #555555 10%, rgba(85, 85, 85, 0) 42%);
  background: linear-gradient(to right, #555555 10%, rgba(85, 85, 85, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #555555;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #555555;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* bootstrap4のCol内での表示乱れ対策 */
.react-datepicker-popper {
  width: max-content;
}

.ag-react-container {
  box-sizing: border-box;
  width: 100%;
}

/* */

.ag-cell {
  overflow: visible;
}

.ag-cell-value {
  max-height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height {
  overflow: visible;
}

.toggle-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.toggle-label {
  width: 36px;
  height: 21px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 15px;
  transition: 0.4s;
  box-sizing: border-box;
}
.toggle-label:after {
  content: '';
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.toggle-input:checked + .toggle-label {
  background-color: #007bff;
}
.toggle-input:checked + .toggle-label:after {
  left: 20px;
}

.toggle-switch {
  position: relative;
  width: 75px;
  height: 42px;
  margin: auto;
}

.nav-external-link {
  color: rgba(255, 255, 255, 0.5);
  padding: 1rem 0.5rem;
}

.nav-external-link:hover {
  color: #fff;
  text-decoration: none;
}

.FiltersPane {
  font-size: small;
}

.FiltersPane-body {
  height: calc(100vh - 56px - 48px - 64px);
  overflow: scroll;
  padding: 0.5rem;
}
